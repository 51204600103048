<template>
  <div class="login_container">
    <LoginVerificationInner></LoginVerificationInner>
  </div>
</template>
<script>
import LoginVerificationInner from "@/pages/login/loginVerification-inner.vue";
export default {
  mixins: [],

  data() {
    return {};
  },
  components: { LoginVerificationInner },
  computed: {
    // ...mapGetters({
    //   darkMode: "user/darkMode"
    // })
  },
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss"></style>
